import React, { useState, useEffect } from 'react';
import { ProductCategoryColumns } from "../../../CommonComp/TableComponent/EntityTables/Setup/ProductCategoryColumns"
import TableComponent from "../../../CommonComp/TableComponent/TableComponent"
import { getProductCategories } from '../../../service/SetupServices/ProductCategoryService';



function ProductCategory() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [productCategoryData, setData] = useState([]);

    useEffect(() => {
        fetchProductCategories();
    }, []);


    const fetchProductCategories = async () => {
        const response = await getProductCategories();
        try {
            setData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }


    const openModal = () => {
        // setUnitTypeName('');
        setIsModalOpen(true);
    };

    return (
        <div>
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h2>Product Categories</h2>
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-11">
                                </div>
                                <div className="col-md-1">
                                    <button
                                        style={{ marginBottom: '10px' }}
                                        type="button"
                                        onClick={openModal}
                                        className="btn btn-block btn-success">Add
                                    </button>
                                </div>
                            </div>

                            <TableComponent
                                columns={ProductCategoryColumns}
                                data={productCategoryData}

                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProductCategory