import React, { useState, useEffect } from 'react';
import './settings.css'
import SettingsSidebar from '../SettingsSidebar/SettingsSidebar';
import UnitType from '../UnitType/UnitTypes';
import InventoryTypes from '../InventoryType/InventoryTypes';
import ProductCategory from '../ProductCategory/ProductCategory';
import TaxSlab from '../TaxSlab/TaxSlab';

function Settings() {
    const [activeComponent, setActiveComponent] = useState('InventoryTypes');

    // Handler to update the active component
    const handleItemClick = (componentName) => {
        setActiveComponent(componentName);
    };

    // Render the selected component
    const renderComponent = () => {
        switch (activeComponent) {
            case 'InventoryTypes':
                return <InventoryTypes />;
            case 'ProductCategories':
                return <ProductCategory />;
            case 'UnitTypes':
                return <UnitType />;
            case 'TaxTypes':
                return <TaxSlab />;
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="content-wrapper d-flex">
                <div>
                    <SettingsSidebar onItemClick={handleItemClick} />
                </div>
                <div className="container-fluid">
                    {renderComponent()}
                </div>
            </div>
        </div>
    )
}

export default Settings