import { get, post } from "../../APIHandler";
export const getProductCategories = async () =>{
    try {
        const productCategories = await get('/ProductCategory/getproductcategorylist');
        console.log("Service Response ========>", productCategories);
        return productCategories;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};