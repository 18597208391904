import React from 'react'
import './settingsSidebar.css'

function SettingsSidebar({ onItemClick }) {
  return (
    <div className='inventory-sidebar'>
        <h4>Products</h4>
        <ul>
          <li onClick={() => onItemClick('InventoryTypes')}>Manage Inventory Types</li>
          <li onClick={() => onItemClick('ProductCategories')}>Manage Product Categories</li>
          <li onClick={() => onItemClick('UnitTypes')}>Manage Unit Types</li>
          <li onClick={() => onItemClick('TaxTypes')}>Manage Tax Types</li>
        </ul>
    </div>
  )
}

export default SettingsSidebar