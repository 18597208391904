import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../../CommonComp/TableComponent/TableComponent';
import { getTaxSlabs } from '../../../service/SetupServices/TabSlabService';
import { TaxSlabColumns } from '../../../CommonComp/TableComponent/EntityTables/Setup/TaxSlabColumns';

function TaxSlab() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [taxSlabData, setData] = useState([]);

    useEffect(() => {
        fetchTaxSlabs();
    }, []);


    const fetchTaxSlabs = async () => {
        const response = await getTaxSlabs();
        try {
            setData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    const openModal = () => {
        //  setUnitTypeName('');
        setIsModalOpen(true);
    };

    return (
        <div>
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h2>Tax Slab</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-11">
                                </div>
                                <div className="col-md-1">
                                    <button
                                        style={{ marginBottom: '10px' }}
                                        type="button"
                                        onClick={openModal}
                                        className="btn btn-block btn-success">Add
                                    </button>
                                </div>
                            </div>

                            <TableComponent
                                columns={TaxSlabColumns}
                                data={taxSlabData}

                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default TaxSlab