import { get, post } from "../../APIHandler";
export const getTaxSlabs = async () =>{
    try {
        const taxSlabs = await get('/TaxSlab/gettaxslablist');
        console.log("Service Response ========>", taxSlabs);
        return taxSlabs;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};