export const TaxSlabColumns = [
    {
         Header: 'Name',
         accessor: 'taxName'
     },{
        Header: 'Description',
        accessor: 'taxDescription'
    },{
         Header: 'Percentage',
         accessor: 'taxPercentage'
     },{
        Header: 'Active',
        accessor: 'isActive'
    }
 ]