import { get, post } from "../../APIHandler";
export const getUnitTypes = async () =>{
    try {
        const unitTypes = await get('/Units/getunitslist');
        console.log("Service Response ========>", unitTypes);
        return unitTypes;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};