import React, { useState, useEffect } from 'react';
import './inventoryTypes.css'
import { useNavigate } from "react-router-dom";
import { InventoryTypeColumns } from '../../../CommonComp/TableComponent/EntityTables/Setup/InventoryTypes';
import { AddInventoryType, getInventoryTypes } from '../../../service/SetupServices/InventoryTypeService';
import TableComponent from '../../../CommonComp/TableComponent/TableComponent';
import { toast } from 'sonner';

function InventoryTypes() {

    const navigate = useNavigate();

    const [inventoryTypeData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inventoryTypeName, setInventoryTypeName] = useState('');

    useEffect(() => {
        // fetchInventoryTypes();
    }, []);

    // const fetchInventoryTypes = async () => {
    //     console.log("Execute Fetch");

    //     const response = await getInventoryTypes();
    //     try {
    //         setData(response.data);

    //     } catch (error) {
    //         setError(error);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    // const addInventoryType = (e) => {
    //     e.preventDefault();
    //     navigate("/add_product");
    // }


    const openModal = () => {
        setInventoryTypeName('');
        setIsModalOpen(true);
    };


    const handleCreateInventoryType = async () => {
        if (inventoryTypeName.trim() === '') {
            alert('please Enter Inventory Type.');
            return;
        }

        const newInventoryType = {
            InventoryTypeName: inventoryTypeName
        };

        try {
            const response = await AddInventoryType(newInventoryType); // axios.post(`${API_URL}/Customer/createnewcustomer`, newCustomer);
            toast.success('Inventory Type added successfully!');
            setIsModalOpen(false);

        } catch (error) {
            console.error('Error posting data:', error);
            toast.error('Failed to Inventory Type. Please try again.');
        }
    };


    return (
        <div>
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h2>Inventory Types</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-11">
                                </div>
                                <div className="col-md-1">
                                    <button
                                        style={{ marginBottom: '10px' }}
                                        type="button"
                                        onClick={openModal}
                                        className="btn btn-block btn-success">Add
                                    </button>
                                </div>
                            </div>

                            <TableComponent
                                columns={InventoryTypeColumns}
                                data={inventoryTypeData}

                            />
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className="modal fade show d-block" id="customerModal-lg" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h6 className="modal-title">Add Inventory Type</h6>
                                <button type="button" className="close" onClick={() => setIsModalOpen(false)}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>


                            <div className="modal-body">
                                <div className="form-row">

                                    <div className="form-group col-md-12">
                                        <label htmlFor="customerName">Inventory Type name<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inventoryTypeNameId"
                                            value={inventoryTypeName}
                                            onChange={(e) => setInventoryTypeName(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" onClick={() => setIsModalOpen(false)}>Close</button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleCreateInventoryType}
                                >
                                    Create
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default InventoryTypes