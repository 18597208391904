import React, { useEffect, useState } from "react";
import axios from "axios";
import CanvasJSReact from "@canvasjs/react-charts";
import { API_URL } from "../CommonComp/APIConstants";
import './home.css'

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Home() {
    const [invoiceData, setInvoiceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dateRange, setDateRange] = useState("thisMonth");
    const [groupByMonth, setGroupByMonth] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        axios
            .post(API_URL + "/Invoice/GetInvoiceList", {}, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                setInvoiceData(response.data);
            })
            .catch((error) => {
                console.error("There was an error fetching the data!", error);
            });
    }, []);

    useEffect(() => {
        filterData();
    }, [dateRange, invoiceData]);

    const filterData = () => {
        const now = new Date();
        let startDateVal, endDateVal;
        let groupByMonthFlag = false;

        switch (dateRange) {
            case "today":
                startDateVal = new Date(now.setHours(0, 0, 0, 0));
                endDateVal = new Date();
                break;
            case "thisWeek":
                startDateVal = new Date(now.setDate(now.getDate() - now.getDay()));
                endDateVal = new Date();
                break;
            case "lastWeek":
                startDateVal = new Date(now.setDate(now.getDate() - now.getDay() - 7));
                endDateVal = new Date(now.setDate(now.getDate() - now.getDay()));
                break;
            case "thisMonth":
                startDateVal = new Date(now.getFullYear(), now.getMonth(), 1);
                endDateVal = new Date();
                break;
            case "lastMonth":
                startDateVal = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                endDateVal = new Date(now.getFullYear(), now.getMonth(), 0);
                break;
            case "thisYear":
                startDateVal = new Date(now.getFullYear(), 0, 1);
                endDateVal = new Date();
                groupByMonthFlag = true;
                break;
            case "lastYear":
                startDateVal = new Date(now.getFullYear() - 1, 0, 1);
                endDateVal = new Date(now.getFullYear() - 1, 11, 31);
                groupByMonthFlag = true;
                break;
            default:
                startDateVal = new Date(now.getFullYear(), now.getMonth(), 1);
                endDateVal = new Date();
        }

        // Update state with calculated start and end dates
        setStartDate(startDateVal.toISOString().split("T")[0]);
        setEndDate(endDateVal.toISOString().split("T")[0]);

        setGroupByMonth(groupByMonthFlag);

        const filtered = invoiceData.filter((invoice) => {
            const invoiceDate = new Date(invoice.invoiceDate);
            if (endDateVal) {
                return invoiceDate >= startDateVal && invoiceDate <= endDateVal;
            }
            return invoiceDate >= startDateVal;
        });

        setFilteredData(filtered);
    };

    const dateToAmountMap = filteredData.reduce((acc, invoice) => {
        const date = new Date(invoice.invoiceDate);

        let key;
        if (groupByMonth) {
            key = date.toLocaleString("default", { month: "short", year: "numeric" });
        } else {
            key = date.toLocaleDateString();
        }

        if (!acc[key]) {
            acc[key] = 0;
        }
        acc[key] += invoice.totalAmountNet;
        return acc;
    }, {});

    const dataPoints = Object.keys(dateToAmountMap).map((date) => ({
        label: date,
        y: dateToAmountMap[date],
    }));

    const options = {
        animationEnabled: true,
        title: {
            text: dateRange.includes("Year") ? "Total Amount Net per Month" : "Total Amount Net per Date",
        },
        axisY: {
            title: "Total Amount Net",
            includeZero: true,
        },
        axisX: {
            title: dateRange.includes("Year") ? "Month" : "Date",
            interval: 1,
            labelAngle: -45,
        },
        data: [
            {
                type: "column",
                dataPoints: dataPoints,
            },
        ],
    };

    return (
        <div>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h2>Dashboard</h2>
                        </div>
                    </div>

                    <div className="row mb-2 d-flex gap-3" >
                        <div className="">
                            <label>Date Range</label>
                            <select
                                value={dateRange}
                                onChange={(e) => setDateRange(e.target.value)}
                                className="addpay-dropdown form-control"
                            >
                                <option value="today">Today</option>
                                <option value="thisWeek">This Week</option>
                                <option value="lastWeek">Last Week</option>
                                <option value="thisMonth">This Month</option>
                                <option value="lastMonth">Last Month</option>
                                <option value="thisYear">This Year</option>
                                <option value="lastYear">Last Year</option>
                            </select>
                        </div>

                        <div className="ml-1">
                            <label>Start Date</label>
                            <input
                                type="date"
                                className="date-input payment-input"
                                value={startDate}
                                readOnly
                            />
                        </div>
                        <div className="ml-1">
                            <label>End Date</label>
                            <input
                                type="date"
                                className="date-input payment-input"
                                value={endDate}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>150</h3>
                                    <p>New Orders</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                <a href="#" className="small-box-footer">
                                    More info <i className="fas fa-arrow-circle-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>
                                        53<sup style={{ fontSize: 20 }}>%</sup>
                                    </h3>
                                    <p>Bounce Rate</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-stats-bars" />
                                </div>
                                <a href="#" className="small-box-footer">
                                    More info <i className="fas fa-arrow-circle-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>44</h3>
                                    <p>User Registrations</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add" />
                                </div>
                                <a href="#" className="small-box-footer">
                                    More info <i className="fas fa-arrow-circle-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h3>65000</h3>
                                    <p>Unique Visitors</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph" />
                                </div>
                                <a href="#" className="small-box-footer">
                                    More info <i className="fas fa-arrow-circle-right" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="chart-body card-body">
                                    {dataPoints.length > 0 ? (
                                        <CanvasJSChart options={options} />
                                    ) : (
                                        <p>No data available for the selected date range.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
