export const UnitTypeColumns = [
    {
         Header: 'Name',
         accessor: 'unitName'
     },{
        Header: 'Description',
        accessor: 'unitDescription'
    },{
         Header: 'Active',
         accessor: 'isActive'
     }
 ]