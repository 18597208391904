export const ProductCategoryColumns = [
    {
         Header: 'Code',
         accessor: 'categoryCode'
     },{
        Header: 'Name',
        accessor: 'categoryName'
    },{
         Header: 'Description',
         accessor: 'categoryDescription'
     },{
        Header: 'Active',
        accessor: 'isActive'
    }
 ]