import { Route, Routes } from "react-router-dom";
import Home from "../Home";
import AddCustomer from "../Customer/AddCustomer";
import Products from "../Product/Products";
import AddProduct from "../Product/AddProduct";
import Suppliers from "../Supplier/Suppliers";
import AddSupplier from "../Supplier/AddSupplier";
import Invoices from "../Invoice/Invoices";
import CreateInvoice from "../Invoice/CreateInvoice";
import AddPurchaseOrder from "../PurchaseOrder/AddPurchaseOrder";
import PurchaseOrderList from "../PurchaseOrder/PurchaseOrderList";
import ViewInvoice from "../Invoice/ViewInvoice";
import ViewPurchaseOrder from "../PurchaseOrder/ViewPurchaseOrder";
import InvoiceComponent from "../Invoice/InvoiceComponent";
import UserList from "../User/UserList";
import AddUser from "../User/AddUser";
import AddProcessBOM from "../Product/AddProcessBOM";
import CrmCustomers from "../CrmCustomer/CrmCustomers";
import AddCrmCustomer from "../CrmCustomer/AddCrmCustomer";
import LoginPage from "../Login/LoginPage";
import Customer from "../Customer/Customer";
import ViewSupplier from "../Supplier/ViewSupplier";
import EditSupplier from "../Supplier/EditSupplier";
import Payments from "../Payment/Payments";
import ViewProduct from "../Product/ViewProduct";
import EditProduct from "../Product/EditProduct";
import ViewCustomer from "../Customer/ViewCustomer";
import EditCustomer from "../Customer/EditCustomer";
import AddPayment from "../Payment/AddPayment";
// import InventoryTypes from "../Setup/InventoryType/InventoryTypes";
import Settings from "../Setup/Settings/Settings";


function AppRoute() {

    return (

        <Routes>
            <Route path="/login" element={<LoginPage/>}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route path="/dashboard" element={<Home />}></Route>

            <Route path="/users_master" element={<UserList />}></Route>
            <Route path="/create_user" element={<AddUser />}></Route>
            <Route path="/customer_master" element={<Customer />}></Route>
            <Route path="customer_master/view/:id" element={<ViewCustomer />} />
            <Route path="customer_master/edit/:id" element={<EditCustomer />} />
            <Route path="/add_customer" element={<AddCustomer />}></Route>

            <Route path="/product_master" element={<Products />}></Route>
            <Route path="product_master/view/:id" element={<ViewProduct />} />
            <Route path="product_master/edit/:id" element={<EditProduct />} />
            <Route path="/add_product" element={<AddProduct />}></Route>
            <Route path="/add_process_bom" element={<AddProcessBOM />}></Route>

            <Route path="/supplier_master" element={<Suppliers />}></Route>
            <Route path="supplier_master/view/:id" element={<ViewSupplier />} />
            <Route path="supplier_master/edit/:id" element={<EditSupplier />} />
            <Route path="/add_supplier" element={<AddSupplier />}></Route>
            <Route path="/invoice_master" element={<Invoices />}></Route>
            <Route path="/create_invoice" element={<CreateInvoice />}></Route>
            <Route path="/purchase_order_master" element={<PurchaseOrderList />}></Route>
            <Route path="/create_purchase_order" element={<AddPurchaseOrder />}></Route>
            <Route path="/view_purchase_order/:purchaseOrderId" element={<ViewPurchaseOrder />}></Route>
            <Route path="/view_invoice" element={<ViewInvoice />}></Route>

            <Route path="/payments" element={<Payments />}></Route>
            <Route path="/add_payment" element={<AddPayment />}></Route>


            <Route path="/crm_customer_master" element={<CrmCustomers />}></Route>
            <Route path="/crm_add_customer" element={<AddCrmCustomer/>}></Route>


            <Route path="/crm_edit_customer/:customerId" element={<AddCrmCustomer/>}></Route>

            <Route path="/sample" element={<InvoiceComponent />}></Route>
            <Route path="/inventory_types" element={<Settings />}></Route>
        
            InventoryTypes
        
        </Routes>

    );


}
export default AppRoute