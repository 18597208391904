import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import TableComponent from "../../../CommonComp/TableComponent/TableComponent"
import { UnitTypeColumns } from '../../../CommonComp/TableComponent/EntityTables/Setup/UnitTypeColumns';
import { getUnitTypes } from '../../../service/SetupServices/UnitTypeService';


function UnitType() {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [unitTypeData, setData] = useState([]);
    const [unitTypeName, setUnitTypeName] = useState('');

    useEffect(() => {
        fetchUnitTypes();
    }, []);

    const fetchUnitTypes = async () => {
        const response = await getUnitTypes();
        try {
            setData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    const openModal = () => {
        setUnitTypeName('');
        setIsModalOpen(true);
    };

    const handleCreateUnitType = async () => {

    }

    return (
        <div>
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h2>Unit Types</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-11">
                                </div>
                                <div className="col-md-1">
                                    <button
                                        style={{ marginBottom: '10px' }}
                                        type="button"
                                        onClick={openModal}
                                        className="btn btn-block btn-success">Add
                                    </button>
                                </div>
                            </div>

                            <TableComponent
                                columns={UnitTypeColumns}
                                data={unitTypeData}

                            />
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className="modal fade show d-block" id="customerModal-lg" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h6 className="modal-title">Add Unit Type</h6>
                                <button type="button" className="close" onClick={() => setIsModalOpen(false)}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="form-row">

                                    <div className="form-group col-md-12">
                                        <label htmlFor="customerName">Unit Type name<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inventoryTypeNameId"
                                            value={unitTypeName}
                                            onChange={(e) => setUnitTypeName(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" onClick={() => setIsModalOpen(false)}>Close</button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleCreateUnitType}
                                >
                                    Create
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default UnitType